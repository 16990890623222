import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes"
import qs from "qs"
import dayjs from "dayjs"
import { useStore } from "vuex"


/**
 * @levels 可查看的权限列表 [{level: 10, isAdmin: true}] || 'all'
 * @title  菜单名称或路由名称  title: "首页"
 * @icon  一级菜单路由的图标, 来自iconfont文件
 * @keepAlive  路由是否进行缓存
 * @comName 进行路由缓存的组件名称, v3 setup默认为文件名称 或组件内部使用的name 
 * @isAdmin  是否为管理可查看
 * @super 是否为超级管理员可查看,
 * @disabled 是否禁用当前路由
 * @hide 是否隐藏当前路由
 * @children 菜单路由的子路由, 子路由列表值为字符串时为跳转路由  为对象时是菜单路由
 * 
 * @function findComName router跳转路由的方式
 * @params1 name 组件名称 必传
 * @params2 params 是否携带参数进行跳转 默认为 {}
 * @params3 list 从那个列表中开始搜索 默认全部列表
 * push和replace 只适用于以获取当前路由的name和path, 如需根据根据名称跳转则使用findComName
 */

const menuRoutes = [
  // {
  //   path: "/",
  //   name: "index",
  //   component: () => import("../views/index.vue"),
  //   meta: { title: "首页", keepAlive: false, icon: "icon-shouye1", levels: "all" },
  //   children: []
  // },
  {
    path: "/",
    name: "indexHome",
    component: () => import("../views/indexHome.vue"),
    meta: { title: "主页", keepAlive: true, icon: "icon-shouye1", comName: "indexHome", levels: "all" },
    children: []
  },
  /* 日程 */
  {
    path: "/scheduleReg",
    name: "scheduleReg",
    meta: { title: "日程管理", icon: "icon-daibanshixiang", levels: "all", notLevel: [42, 44] },
    children: [
      "schedule",
      /* "interaction",
      "prompting", */

      "TaskTracking",
      "ScheduleQuery",
      { title: "数据分析", name: "scheduleAnalyze", levels: [{level:6}], children: ["ranking"]},
    ]
  },
  {
    path: "/dataAnalysis",
    name: "dataAnalysis",
    meta: { title:"数据分析", icon: "icon-shujukanban", levels: [{ level:6 }] },
    children: [
      "feibiCharts","activityData", "recruitmentAnalysis", "propertyAnalysis",
      {
        title: "数据简报",
        name: "briefReport",
        levels: [{level: 6}],
        children: ["depBriefReport", "perBriefReport"]
      }
    ]
  },
  {
    path: "/marketingReg",
    name: "marketingReg",
    meta: { title:"市场营销", icon: "icon-chengshi", levels: [{level:5}] },
    children: ["marketWorkbench"]
  },
  {
    path: "/operation",
    name: "operation",
    meta: { title: "运营管理", icon: "icon-zhexiantu", levels: [{level:37},{level:10},{level:13},{level:14},{level:49},{level:16},{level:17},{level:18},{level:19},{level:20},{level:46},{level:58},{level:59},{level:71},{level:72},{level:73}] },
    children: [
      "operaWorkbench",
      "operatedAnalysis",
      "paidData",
      "competitiveData",
      // "rpaData",
      "rpaControl",
      // "rpaAnalysisOld",
      // "rpaAnalysis"
    ]
  },
  {
    path: "/business",
    name: "business",
    meta: { title: "商采管理", icon: "icon-gongdanqueren", levels: [{level: 11}] },
    children: [
      "purchaseWorkbench"
      /* { name: "writeOff", title: "核销查询", children: ["fixedWriteOff", "specialWriteOff", "operateWriteOff"] },
      {
        name: "erp",
        title: "ERP查询", 
        children: [
          { name: "purchaseQuery", title: "数据管理", children: ["purchaseOrder", "transferOrder", "inventorySheet", "outsourcing", "outbound", "warehousingOrder", "searchErp","purchaseAndinventory"] },
          // "combination",
          "wdtAudit",
          "procureAlert",
          "courierTimeliness",
        ]
      },
      {
        name: "controlData",
        title: "商品资料",
        children: ["Commodity_warehouse","aptitude", "SupplierQualification"]
      },
      {
        name: "operateReg",
        title: "运营日志",
        isAdmin: true,
        children: [
          "operateLogManage",
          "operationsLogs"
        ]
      } */
    ]
  },
  {
    path: "/service",
    name: "service",
    meta: { title: "客服管理", icon: "icon-kefu", levels: [{level:9},{level:23},{level:24},{level:30},{level:32},{level:28},{level:36},{level:33},{level:38},{level:39},{level:40}]},
    children: [
      "customerWorkbench",
      
      // 售前
      /* service_satisfied
      DSR_analysis */


      // 售后
      /* salesEnter
      salesSummary
      outerOutside
      private_order_query */


      // 营销任务
      /* "serviceTaskHome",
      "beforehandStaffAssignments",
      "staffAssignments",
      "fundAllocation",
      "personalWorkbench",
      "allocationtable",
      "abnormal" */


      //紫春数据
      /* "new_evaluation",
      "collect_new_ningyu",
      "envelopes",
      "healthy",
      "cloudMapData" */

      // 小象数据
      /* "goldEvaluation",
      "collect_new_jinhua",
      "revenueExpenditure",
      "second_market", */

      
      // 小象数据
      /* "trickster",
      "whitePool",
      "youmoorderEntry", */

      //营销单数据
      /* order_query
      inkTokOrderQuery
      journal_edit */

      // replenishment


      // 客服三部
      
      /* "analysis_summary",
      "order_entry",
      "fourRefunds", */
      /* Data_query
      authorization
      "customerData",
      operateLogManage
      operationsLogs
      "feibiCharts" */
    ]
  },
  {
    path: "/outside",
    name: "outside",
    component: () => import("../views/service/outside.vue"),
    meta: { title: "外部补单", keepAlive: true, icon: "icon-kefu", comName: "outside", levels: [{level: 42}] },
    children: null
  },
  /* {
    path: "/marketing",
    name: "marketing",
    component: () => import("../views/operation/marketing.vue"),
    meta: { title: "营销数据", keepAlive: true, icon: "icon-zhexiantu", comName: "marketing", levels: [{level: 44}], extra: true },
    children: null
  },
  {
    path: "/realTimeOrder",
    name: "realTimeOrder",
    component: () => import("../views/operation/realTimeOrder.vue"),
    meta: { title: "实时订单下载", keepAlive: true, icon: "icon-kefu", comName: "realTimeOrder", levels: [{level: 44}], extra: true },
    children: null
  }, */
  {
    path: "/finance",
    name: "finance",
    meta: { title: "财务管理", icon: "icon-liujisuan", levels: [{level: 12}] },
    children: [
      "financialWorkbench"
      /* {
        name: "expressBill",
        title: "快递账单",
        children:[
          "cloudWarehouse",
          "expressTemplate",
          "laborCost"
        ]
      },
      {
        name: "calculate",
        title: "数据运算",
        children: [
          "businessMonth",
          "AdditionalCommission",
          "withinCommission",
          "evaluationPrice",
          "collectPrices",
          "AlipayLiushui",
          "cloudPicture",
          "BankStatement",
          "Wangdiantong",
          "businessThrough",
          "earnestMoney",
          "WangdiantongOriginal",
          "WeChatBilling",
          "AlipayStream"
        ]
      },
      {
        name: "financeData",
        title: "数据采集",
        children: [
          "evaluation",
          "collector",
          "incomeAndExpenditure",
          "second_market",
          "salesEnter",
          "cloudMapData",
          "order_entry",
          "taobaoOrders",
          "marketing",
          "financeHealthy",
          "outerOutside",
          "inkTokOrderQuery",
          "summaryScreening"
        ]
      },
      "storeRealTimeOrder",
      {
        name: "performance",
        title: "运营绩效",
        children: [
          "goods",
          "deductionPoint",
          { name: "cost", title: "运营费用", children: ["product", "throughTheTraffic", "dailyFee", "softwareCost", "otherFee"]},
          { name: "writeOff", title: "核销查询", children: ["fixedWriteOff", "specialWriteOff", "operateWriteOff"] },
          "financeMonthData",
          { name: "profit", title: "运营绩效", children: ["dayPerformance", "monthPerformance", "teamPerformance"]},
          "performanceSummary"
        ]
      },
      {
        name: "erp",
        title: "ERP管理",
        children: [
          { name: "inStockReg", title: "库存管理", children: ["outOfTheWarehouse", "searchErp", "requisition"]},
        ]
      },
      "logisticsInfo",
      "financeCost",
      "dailyReview",
      "costCalculate" */
    ]
  },
  {
    path: "/hr",
    name: "hr",
    meta: { title: "人事管理", icon: "icon-zuzhiqunzu", levels: [{level:7}] },
    children: [
      { name: "recruitmentReg", title: "招聘管理", children: [
          "hrHome",
          "annualAnalysis",
          "resume_query",
          "Add_resume",
          "interviewManagement",
          "entryReg",
          { name: "talentReg", title: "人才管理", children: ["Talent_pool", "resume_Recyclebin"]},
          "Reception_Admin",
          "Employed",
          "Detailpage",
          "Interviewed",
          "Dayinterview",
          "Numdrain"
        ]
      },
      { name: "performanceReg", title: "绩效管理", children: ["operatEperformance"]},
      "attendanceInformation",
      "salaryReg",
      "trainingReg",
      "announcement",
    ]
  },
  {
    path: "/administrative",
    name: "administrative",
    meta: { title: "行政管理", icon: "icon-ceshishenqing", levels: [{level:8}] },
    children: [
      { name: "xzFixedAsset", title: "固定资产", children: ["dataStatistics", "xzFixedAsset", "officeProcurement", "officeWarehouse"]},
      "conference",
      "announcement",
    ]
  },
  {
    path: "/it",
    name: "it",
    meta: { title:"数据中心", icon: "icon-guize", levels: [{level:6}, {level:43}] },
    children: [
      { name: "assets", title: "资产管理", children: ["assetsHome", "purchaseReg", "storehouseReg", "sortManagement"]},
      { name: "ann", title: "公告管理", levels: [{level: 6}], children: ["announcement", "conference"]},
      { name: "backstage", title: "后台管理", levels: [{level: 6}], children: ["personnel", "userGroups", "storeReg", "storeLimitsReg", "operateStoreLimitReg", "authorityReg", "logReg", "databaseReg", "editionReg", "testPush", "tempTest"]},
      { name: "dataTreating", title: "数据管理", levels: [{level:6}], children: ["panelData", "targetcostManagement"] }
    ]
  },
  {
    path: "/legalReg",
    name: "legalReg",
    meta: { title:"法务管理", icon: "icon-anquan", levels: [{ level:41 }] },
    children: [
      {
        name: "controlData",
        title: "风控数据",
        children: ["aptitude", "SupplierQualification", "interimPeriod", "certificate"]
      },
      "institution",
      "legal_costComparison"
    ]
  },
  /* {
    path: "/institute",
    name: "institute",
    meta: { title:"紫春商学院", icon: "icon-icon_bangzhuwendang", levels: [{level:31}] },
    children: [
      "instituteHome",
      "questionBank",
      {
        name: "examination",
        title: "考试分配",
        children: ["simulation", "formalExamination"]
      },
      {
        name: "examStatistics",
        title: "统计分析",
        disabled: true
        // children: ["simulation", "formalExamination"]
      }
    ]
  }, */
  // {
  //   path: "/activityData",
  //   name: "activityData",
  //   meta: { title: "活动数据分析", fullScreen: true, icon: "icon-shujukanban", levels: [{level:31}] },
  //   children: null
  // },
  {
    path: "/daniuBusiness",
    name: "daniuBusiness",
    meta: { title:"牛肉事业部", icon: "icon-niu", levels: [{level: 68},{level: 70}], comName: "daniuBusiness"},
    children: [
      "daniuWorkbench"
    ]
  },
  {
    path: "/externalOperations",
    name: "externalOperations",
    meta: { title:"外部运营管理", icon: "icon-waibu", levels: [{level: 51}], comName: "externalOperations"},
    children: [
      "goods",
      "marketing",
      { name: "cost", title: "运营费用", children: ["product", "throughTheTraffic", "dailyFee", "softwareCost", "otherFee"]},
      { name: "profit", title: "运营利润", children: ["dayProfit", "monthProfit", "teamProfit", "storeProfit"]},
      {
        name: "erp",
        title: "ERP查询",
        children: [
          "searchErp",
          "inventoryAlert",
          "combination",
          "combinationReg",
          "combinationEra",
          "wdtAudit"
        ] 
      },
      {
        name: "operateReg",
        title: "运营日志",
        children: [
          "operateLogManage",
          "operationsLogs"
        ]
      },
    ]
  },
  {
    path: "/otherOperations",
    name: "otherOperations",
    meta: { title:"其他平台运营管理", icon: "icon-qitadingdan", levels: [{level: 48},{level: 31}]},
    children: [
      "goods",
      "marketing",
      { name: "cost", title: "运营费用", children: ["product", "throughTheTraffic", "dailyFee", "softwareCost", "otherFee"]},
      { name: "profit", title: "运营利润", children: ["dayProfit", "monthProfit", "teamProfit", "storeProfit"]},
      {
        name: "erp",
        title: "ERP查询",
        children: [
          "searchErp",
          "inventoryAlert",
          "combination",
          "combinationReg",
          "combinationEra",
          "wdtAudit"
        ] 
      },
      {
        name: "operateReg",
        title: "运营日志",
        children: [
          "operateLogManage",
          "operationsLogs"
        ]
      },
    ]
  },
  {
    path: "/other",
    name: "other",
    meta: { title: "其他数据", icon: "icon-qita", levels: [{level: 44}]},
    children: ["marketing", "realTimeOrder", "goods"]
  },
  {
    path: "/courierStops",
    name: "courierStops",
    component: () => import("../views/operation/courierStops.vue"),
    meta: { title: "仓库未发统计", keepAlive: true, comName: "courierStops", icon: "icon-huowudui", levels: [{level: 6}]},
    children: null
  },
  // { name: "order_data", title: "有墨数据", levels: [{level: 47}], children: ["order_query", "inkTokOrderQuery", "journal_edit"]},
  // { name: "order_data", title: "有墨数据", levels: [{level: 47}], children: ["order_query", , "journal_edit"]},
  {
    path: "/other_youmoorderEntry",
    name: "other_youmoorderEntry",
    component: () => import("../views/service/youmoorderEntry.vue"),
    meta: { title: "营销单录入", keepAlive: true, icon: "icon-ceshishenqing", levels: [{level: 55},{level: 65},{level:74},{level:75}], comName: "other_youmoorderEntry"},
    children: null
  },
  {
    path: "/inkTokOrderQuery",
    name: "inkTokOrderQuery",
    component: () => import("../views/service/inkTokOrderQuery.vue"),
    meta: { title: "营销单查询", keepAlive: true, icon: "icon-moshubang", levels: [{level: 47},{level: 60},{level: 61},{level: 62},{level: 63},{level: 64},{level: 68},{level: 55},{level: 70}, {level: 38},{level: 23, isAdmin: true}], comName: "inkTokOrderQuery"},
    children: null
  },
  /* {
    path: "/technologicalProcess",
    name: "technologicalProcess",
    meta: { title: "SOP流程管理", keepAlive: true, icon: "icon-shujuwajue", comName: "inkTokOrderQuery"},
    component: () => import("../views/marketplace/technologicalProcess.vue"),
    children: null
  }, */
  /* {
    path: "/jq",
    name: "jq",
    component: () => import("../views/other/lottery.vue"),
    meta: { title: "轮盘抽奖", keepAlive: false, icon: "icon-moshubang", fullScreen: true, comName: "lottery"},
    children: null
  } */
]

const extraRoute = [
  {
    path: "/not/:status",
    name: "not",
    component: () => import("../views/login/level.vue")
  },
  {
    path: "/particulars",
    name: "particulars",
    component: ()=>import("../views/it/property/particulars.vue")
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/additional/privacyPolicy.vue")
  },
  {
    path: "/uploadFile",
    name: "uploadFile",
    component: () => import("../views/additional/uploadFile.vue")
  },
  {
    path: "/:catchAll(.*)",
    redirect: '/not/404'
  }
]

let routesList = [...menuRoutes, ...routes]


let arr = []
let rs = []
// 过滤可跳转的路由 和 父路由
let list = routesList.filter(v => {
  if (v.children) return v
  // 菜单路由, 且没有子路由
  else if (v.children === null) rs.push(v)
  else arr.push(v)
})
// console.log(routesList)

// 遍历路由, 整理树状结构
let menuList = recursionMap(list, [...arr, ...rs])

// 递归设置 key 和 path name
menuList = recursiveCreation([...menuList, ...rs])


// console.log(menuList)
function recursiveCreation(list, parentKey = '', path, parentObj) {
  return list.map((v, i)=>{
    // 没有标签的话初始化标签
    if (!v.meta) v.meta = {}

    // 父路由的下标, 用来储存key
    let key
    if (parentKey) key = `${parentKey}-${i}`
    else {
      // 父节点的路径
      path = v.path
      key = `sub-${i}`
    }
    // 设置路由的key
    v.meta.key = key

    // 拷贝对象
    let t = copyObject(v)

    // 全屏数据展示
    if (v.meta.fullScreen) return {...t}
    
    let metaKey = t.meta.key.replace(/sub-|-/g, "")
    // 设置跳转路由的名称 和 路径
    t.name = t.name + metaKey
    if (!t.levels && !t.meta.levels && parentObj) {
      var l = typeof parentObj?.levels == 'string'?parentObj.levels:[...parentObj.levels]
    }
    // 设置权限
    t.meta.levels = t.levels || t.meta.levels || l
    
      // 判断是否有子路由
    if (t.children?.length) {
      // 有子路由 设置title
      t.meta.title = t.title || t.meta.title
      // 菜单路由没有权限则复用上级的权限
      // 设置path 路径
      t.path = t.path || t.meta.key
      // 递归调用  key 父节点的key  path 父节点的 path
      t.children = recursiveCreation(t.children, key, path, t.meta)
    } else {
      // 过滤首页, 不进行设置
      if (v.path == '/' || v.path == '/indexHome') return v
      t.path = (path || '') + v.path + metaKey
    }

    return {...t}
  })
}


// 根据childComponent获取树状结构列表
function recursionMap(arr, list) {
  return arr.map(v => {
    // 根据类型递归赋值children
    if (typeof v == "object" && v.children) { 
      v.children = recursionMap(v.children, list);

      return v
      // 查找符合条件的路由, 根据名称进行匹配
    } else return {...list.find(n => n.name == v)}
  // 过滤掉没有找到文件的
  }).filter(v=>v.name)
}

// 执行深拷贝对象
function copyObject(obj) {
  let newObj = obj instanceof Array ?[]:{}
  Object.keys(obj).forEach(item=>{
    // component 路由缓存不进行拷贝
    if (obj[item] instanceof Object && item != "component") {
      newObj[item] = copyObject(obj[item])
    } else {
      newObj[item] = obj[item]
    }
  })
  return newObj
}

// 设置线上和线下不同的路由
let dev = location.hostname == 'localhost'
let loginCom = ()=>import('../views/login/login.vue')
var concise = ()=>import('../views/login/index.vue')

// 设置其他登录方式 进行设置路由编码
let randomCode = sessionStorage.getItem("randomCode") || ''
if (!randomCode) {
  let str = dayjs().format("YYMMDD")
  let arr = str.split('')
  let offset = parseInt(arr[arr.length - 1]) + 65
  randomCode = arr.map(v=>String.fromCharCode(parseInt(v) + offset)).toString().replace(/,/g, '')
}
sessionStorage.setItem("randomCode", randomCode)


let jumpTo = routes.map(v=>{
  return {
    ...v,
    meta: {...v.meta, key: v.name, hide: true}
  }
})
const router = new createRouter({
  history: createWebHistory(""),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("../views/home.vue"),
      children: [...menuList, ...jumpTo]
    },
    {
      path: "/login",
      name: "login",
      component: dev?concise:loginCom
    },
    {
      path: `/${randomCode}`,
      name: randomCode,
      component: dev?loginCom:concise
    },
    ...extraRoute
  ]
})


/**
 * 根据组件名称进行跳转, 路由后面拼接有标识, 所以无法根据路由进行跳转
 * @param {string} name 要跳转组件的名称
 * @param {object} params 需要携带的参数
 * @param {array} list 从那个列表中开始查
 * @param {boolean} open 是否在新窗口打开  
 */
router.findComName = function(name, params = {}, open = false, list = menuList) {
  if (!name) return "无组件名称"

  let info = routes.find(v=>v.name == name)
  let d = qs.stringify(params)
  let path = info.path + (d ? `?${d}` : '')
  if (open) {
    let routeUrl = this.resolve(path)
    window.open(routeUrl.href, '_blank');
  } else if (Object.keys(params).length) {
    // this.push(path)
    this.push({name: info.name, params})
  } else {
    this.push(path)
  }

  
  /* return list.find(v=>{
    if (v.children?.length) {
      this.findComName(name, params, open, v.children)
    } else {
      // 根据组件名称进行查找
      if (v.meta.comName == name) {
        // 查找第几个组件
        let d = qs.stringify(params)
        let path = v.path + (d ? `?${d}` : '')
        if (open) {
          let routeUrl = this.resolve(path)
          window.open(routeUrl.href, '_blank');
        } else if (Object.keys(params).length) {
          // this.push(path)
          this.push({name: v.name, params})
        } else {
          this.push(path)
        }
      }
    }
  }) */
}


export default router;
